<template>
  <div>
    <bread-cum v-bind:breadcrumbItems="['จัดการสมาชิก', 'เพิ่มบัญชีธนาคาร']" />
    <ValidationObserver ref="observer">
      <div class="row mx-0 py-2 rounded">
        <div class="col-12 mb-2">
          <ValidationProvider rules="required" v-slot="v">
            <label for="userId" class="mb-0"
              ><i class="fas fa-user"></i> #สมาชิก</label
            >
            <input
              id="userId"
              v-model.number="form.userId"
              type="text"
              class="form-control"
              placeholder="#สมาชิก"
            />
            <span class="text-danger">{{ v.errors[0] }}</span>
          </ValidationProvider>
        </div>

        <div class="col-12 mb-2">
          <ValidationProvider rules="required" v-slot="v">
            <label class="mb-0" for="bankCode"
              ><i class="fas fa-university"></i> เลือกธนาคาร</label
            >
            <select class="form-control" id="bankCode" v-model="form.bankCode">
              <option value="" disabled>กรุณาเลือกธนาคาร</option>
              <option value="BBL">BBL ธ.กรุงเทพ</option>
              <option value="KBANK">KBANK ธ.กสิกรไทย</option>
              <option value="KTB">KTB ธ.กรุงไทย</option>
              <option value="SCB">SCB ธ.ไทยพาณิชย์</option>
              <option value="BAY">BAY ธ.กรุงศรีอยุธยา</option>
              <option value="CIMB">CIMB ธ.ซีไอเอ็มบี</option>
              <option value="GSB">GSB ธ.ออมสิน</option>
              <option value="KK">KIATNAKIN ธ.เกียรตินาคิน</option>
              <option value="LHB">LHBANK ธ.แลนด์ แอนด์ เฮ้าส์</option>
              <option value="SC">STANDARD ธ.สแตนดาร์ดชาร์เตอร์ด (ไทย)</option>
              <option value="TISCO">TISCO ธ.ทิสโก้</option>
              <option value="TTB">TTB ธ.ทหารไทยธนชาต</option>
              <option value="UOB">UOB ธ.ยูโอบี</option>
              <option value="BAAC">BAAC ธกส</option>
            </select>
            <span class="text-danger">{{ v.errors[0] }}</span>
          </ValidationProvider>
        </div>

        <div class="col-12 mb-2">
          <ValidationProvider rules="required" v-slot="v">
            <label for="accountName" class="mb-0"
              ><i class="fas fa-id-card"></i> ชื่อบัญชี</label
            >
            <input
              id="accountName"
              v-model="form.accountName"
              type="text"
              class="form-control"
              placeholder="ชื่อ นามสกุล หน้าสมุดบัญชี"
            />
            <span class="text-danger">{{ v.errors[0] }}</span>
          </ValidationProvider>
        </div>

        <div class="col-12 mb-2">
          <ValidationProvider rules="required|min:10|numeric" v-slot="v">
            <label for="accountNumber" class="mb-0"
              ><i class="fas fa-clipboard-check"></i> เลขที่บัญชี
            </label>
            <input
              id="accountNumber"
              v-model="form.accountNumber"
              type="text"
              class="form-control"
              placeholder="ระบุเฉพาะตัวเลข"
              maxlength="17"
            />
            <span class="text-danger">{{ v.errors[0] }}</span>
          </ValidationProvider>
        </div>

        <div class="col-12 mb-3">
          <ValidationProvider
            :rules="`required|is:${form.accountNumber}`"
            v-slot="v"
          >
            <label for="bankNumberAgain" class="mb-0"
              ><i class="fas fa-check-double"></i> ยืนยันเลขที่บัญชี
            </label>
            <input
              id="accountNumberAgain"
              v-model="form.accountNumberAgain"
              type="text"
              class="form-control bankinput"
              placeholder="ยืนยันเลขที่บัญชีอีกครั้ง"
              maxlength="17"
            />
            <span class="text-danger">{{ v.errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="col-6">
          <button class="btn btn-secondary w-100" @click="$router.go(-1)">
            ยกเลิก
          </button>
        </div>
        <div class="col-6">
          <button class="btn btn-primary w-100" @click="addBankAccount">
            เพิ่มบัญชี
          </button>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        bankCode: "",
      },
    }
  },
  methods: {
    async addBankAccount() {
      let observer = this.$refs.observer
      let isValid = await observer.validate()
      if (!isValid) {
        this.util.focusError(observer)
        return
      }

      this.swal.processing()
      this.axios({
        method: "post",
        url: "bank/",
        data: this.form,
      })
        .then(() => {
          this.$swal({
            icon: "success",
            text: "เพิ่มบัญชีสำเร็จ",
          }).then((result) => {
            if (result.isConfirmed) {
              this.$router.push("/bank/account")
            }
          })
        })
        .catch((error) => {
          this.$swal({
            icon: "error",
            title: "เพิ่มบัญชีไม่สำเร็จ",
            text: error.response.data.description,
          })
        })
    },
  },
}
</script>